<template>
    <div>
        <v-dialog
            v-model="showDialogPinCode"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Enter the pin code
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-text-field
                                class="pinCode"
                                v-model="pinCode"
                                autocomplete="new-password"
                                type="password"
                                single-line
                                outlined                                
                            >
                            </v-text-field>
                            <!-- <v-otp-input
                                v-model="pinCode"
                                length="12"
                                plain
                            ></v-otp-input> -->
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
                            :disabled="pinCodeIsEmpty"
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    // import { VOtpInput } from "vuetify/lib";

	export default {

        mixins: [Helpers],

        // components: {
        //     VOtpInput
        // },

        props: {
			
            id: {
                type: Number,
                default: 0
            },

            pinCodeToValidate: {
                default: null
            },

			showDialogPinCode: {
				default: false
			},
		},
		
        data: () => ({
			
            pinCode: '',
		}),

        computed: {
            pinCodeIsEmpty() {
                return this.pinCode == null || this.pinCode == undefined || this.pinCode == '';
            },
        },

        methods: {

            cancel() {
                this.$emit('update:showDialogPinCode', false);
            },

            async confirm() {

                if (this.pinCode != this.pinCodeToValidate) {
                    this.showToast("warning", "Waning!", "Invalid pin code.");
                }
                else {
                    this.showToast("success", "Success!", "Pin code validated successfully!");                    
                    this.$emit('methodConfirmToCall');
                    this.cancel();
                }
            }
        }
    };
</script>

<style>
    .pinCode > .v-input__control {
        font-size: 40px;
        text-align: center !important;
    }

    .pinCode > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: center !important;
        letter-spacing: 15px;;
    }
</style>